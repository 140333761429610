import { useGlobalStore } from '@/shared/store/provider'
import { QuizFlowError } from '@/shared/utils/errorClasses'

import { HOUSING_LIST } from '../../QuickSearch/utils'

export const useQuizInternalValue = () => {
  const { setHousingType } = useGlobalStore((state) => state.housingInfo)

  const checkIfValueIsHouseType = (value: string) => {
    if (Object.keys(HOUSING_LIST).includes(value)) {
      const housingType = value as keyof typeof HOUSING_LIST

      setHousingType(housingType)
    } else {
      new QuizFlowError('Internal value is provided but it is not used')
    }
  }

  return {
    checkIfValueIsHouseType,
  }
}
