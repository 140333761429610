'use client'

import { useTranslations } from 'next-intl'

import type { BreadcrumbItem } from '@fortum/elemental-ui'
import { Box, Breadcrumb, Grid, spacing } from '@fortum/elemental-ui'

import { countryConfig } from '@/shared/countryConfig'
import { useTheme } from '@/shared/hooks/useTheme'
import { Link } from '@/shared/navigation'
import { BreadCrumbListJsonLd } from '@/shared/scripts/StructuredDataMarkup'

export type ColorVariant = 'default' | 'secondary'

export type PageBreadcrumbComponentProps = {
  breadcrumbs: BreadcrumbItem<typeof Link>[]
  homeUrl: string
  colors: ColorProps<'background', never>
}

export const PageBreadcrumbComponent = ({
  breadcrumbs,
  homeUrl,
  colors,
}: PageBreadcrumbComponentProps) => {
  const { colors: themeColors } = useTheme()
  const t = useTranslations('breadcrumb')
  const url =
    typeof window !== 'undefined'
      ? `${window.location.origin}${countryConfig.basePath}`
      : process.env.BASE_URL || ''

  const mobileItem = breadcrumbs.at(-1)

  return (
    <Box pv={spacing.xxxs} backgroundColor={colors.background}>
      {BreadCrumbListJsonLd(url, breadcrumbs)}
      <Grid data-testid="breadcrumbs">
        <Breadcrumb
          tag={Link}
          items={breadcrumbs}
          mobileItem={mobileItem}
          color={themeColors.textLink}
          disableVisited={true}
          aria-label={t('ariaLabel')}
          homeAriaLabel={t('homeAriaLabel')}
          homeUrl={homeUrl}
          mv={0}
        />
      </Grid>
    </Box>
  )
}
