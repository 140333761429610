'use client'

import { useEffect, useMemo, useRef, useState } from 'react'

import { spacing } from '@fortum/elemental-ui'

import { getActiveContractTemplate } from '@/open-web/utils/purchaseFlowUtils'
import { ContractHero } from '@/shared/components/Hero/ContractHero'
import { Section } from '@/shared/components/Section'
import { ContractStickyFooter } from '@/shared/components/StickyFooter/ContractStickyFooter'
import type {
  CampaignConfigurationEntry,
  ContractHeroSectionEntry,
} from '@/shared/contentful/types'
import type { EnrichedContractProduct } from '@/shared/services/campaignDataResolver'
import { useDeliveryInfo } from '@/shared/utils/gtm/gtmHelper'

import { ProductJsonLd } from '../ContractListSection/ProductJsonLd'

export type ContractHeroSectionProps = Omit<
  ContractHeroSectionEntry,
  'contract' | 'campaignConfiguration' | 'sys'
> & {
  /**
   * Enriched data from Common Backend and Shared Contentful.
   */
  contractProduct: EnrichedContractProduct
  /**
   * Extra information about campaign that selected product belongs to.
   */
  campaignConfiguration: CampaignConfigurationEntry
}

/**
 * Section which presents the main details of a contract.
 */
export const ContractHeroSection: React.FC<ContractHeroSectionProps> = ({
  contractProduct,
  campaignConfiguration,
  ...heroFields
}) => {
  // Sicky footer logic parts
  const [isFooterShown, setFooterShown] = useState(false)
  const heroRef = useRef<HTMLDivElement>(null)
  const footerRef = useRef<HTMLDivElement>(null)
  const { estimatedConsumption } = useDeliveryInfo()

  const activeContractTemplate = useMemo(
    () =>
      getActiveContractTemplate(
        contractProduct,
        campaignConfiguration.campaignId,
        estimatedConsumption,
      ),
    [estimatedConsumption, contractProduct, campaignConfiguration.campaignId],
  )

  const productJsonLdProps = {
    name: contractProduct.cfData.title || '',
    description: activeContractTemplate.cfData.description || '',
    currency: activeContractTemplate.currency,
    addons: activeContractTemplate.availableAddons,
    tariffElements: activeContractTemplate.tariffElements,
  }

  // Setup effect to add window listener
  useEffect(() => {
    // Local-scoped duplicate to sych state with local values
    let isFooterShownLocal = isFooterShown

    const scrollEventListener = () => {
      if (heroRef.current) {
        const target = heroRef.current
        const bottomLinePosition = target.offsetTop + target.offsetHeight
        const currentScrollPosition = window.scrollY

        if (currentScrollPosition > bottomLinePosition) {
          if (!isFooterShownLocal) {
            setFooterShown(true)
            isFooterShownLocal = true

            // Add footer offset
            if (footerRef.current) {
              const footer = footerRef.current
              document.body.style.paddingBottom = `${footer.offsetHeight}px`
            }
          }

          return
        }

        if (isFooterShownLocal) {
          setFooterShown(false)
          isFooterShownLocal = false

          // Remove footer offset
          document.body.style.paddingBottom = `none`
        }
      }
    }

    window.addEventListener('scroll', scrollEventListener)
    // Call event once to set up initial state on page reload
    scrollEventListener()

    // Cleansing the event handler
    return () => {
      window.removeEventListener('scroll', scrollEventListener)

      // Remove footer offset (just in case)
      document.body.style.paddingBottom = `none`
    }
  }, [heroRef, footerRef])

  return (
    <div ref={heroRef}>
      <Section
        paddingVertical={{ default: spacing.xs, l: spacing.l, xl: spacing.xxl }}
        data-testid="contract-hero-section"
        textAlign="left"
      >
        {/* Separate prop passing is needed for correct typing of a contract */}
        <ContractHero
          {...heroFields}
          contractTemplate={activeContractTemplate}
          contractProduct={contractProduct}
          campaignConfiguration={campaignConfiguration}
        />
        <ContractStickyFooter
          isShown={isFooterShown}
          ref={footerRef}
          contractTemplate={activeContractTemplate}
          campaignConfiguration={campaignConfiguration}
          contractProduct={contractProduct}
        />
      </Section>
      <ProductJsonLd {...productJsonLdProps} />
    </div>
  )
}
