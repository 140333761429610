'use client'

import { Box, Col, Grid, Heading, Row, lineHeights, spacing } from '@fortum/elemental-ui'

import type { QuizSectionEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'
import { useRouter } from '@/shared/navigation'
import { routes } from '@/shared/routes'
import { useGlobalStore } from '@/shared/store/provider'
import { logError } from '@/shared/utils/error'
import { QuizFlowError } from '@/shared/utils/errorClasses'

import { useQuizInternalValue } from '../hooks/useQuizInternalValue'
import { QuizAdditionalInfoBox } from '../QuizAdditionalInfoBox'
import { QuizAnswerButton } from '../QuizAnswerButton'
import type { Answer } from '../types'

export type QuizSectionProps = QuizSectionEntry

export const QuizSection = ({ firstQuestion: question }: QuizSectionProps) => {
  const { colors } = useTheme()

  const { onAnswerSelected, resetQuizFlow, resetIsQuizComplete } = useGlobalStore(
    (store) => store.quizFlow,
  )
  const { checkIfValueIsHouseType } = useQuizInternalValue()
  const router = useRouter()
  const formattedQuestion = {
    ...question,
    id: question?.sys?.id,
    answers:
      question?.answersCollection?.items.filter(Boolean).map((answer) => ({
        ...answer,
        id: answer.sys.id,
        questionId: question?.sys?.id,
        nextQuestionId: answer.nextQuestion?.sys.id,
      })) || [],
  }

  const handleOnAnswerSelected = (answer: Answer) => {
    resetQuizFlow()
    resetIsQuizComplete()

    if (answer.internalValue) {
      checkIfValueIsHouseType(answer.internalValue)
    }

    onAnswerSelected(answer)
    if (question?.slug) {
      router.push({
        pathname: routes.QUIZ,
        params: { slug: question.slug },
      })
    }
  }

  if (!formattedQuestion.slug) {
    logError(new QuizFlowError(`Slug is not provided in first question`))
    return null
  }

  const backgroundImageUrl = formattedQuestion.backgroundImage?.url

  return (
    <Box
      background={colors.oceanEmeraldInverted}
      height={{ default: 'fit-content' }}
      overflow="hidden"
      display="flex"
      flexDirection="column"
    >
      <Box
        flex={1}
        background={{
          default: `${backgroundImageUrl ? `url(${backgroundImageUrl})` : 'none'} right bottom / 80% no-repeat`,
          s: `${backgroundImageUrl ? `url(${backgroundImageUrl})` : 'none'} right bottom / 50% no-repeat`,
        }}
        paddingVertical={{ default: spacing.xxs, m: spacing.m }}
      >
        <Grid height={{ default: '100%' }}>
          <Row>
            <Col
              xxs={12}
              m={11}
              display="flex"
              flexDirection="column"
              gap={{ default: spacing.s, s: spacing.m }}
            >
              <Heading
                level={2}
                weight="medium"
                color={colors.textLightOnDark}
                lineHeight={lineHeights.tight}
              >
                {formattedQuestion.question}
              </Heading>

              <Box
                display="Flex"
                flexWrap="wrap"
                alignItems="stretch"
                gap={{ default: spacing.xxs }}
              >
                {formattedQuestion.answers.map((answer) => (
                  <QuizAnswerButton
                    key={answer.id}
                    label={answer.answer}
                    iconId={answer.icon}
                    highlighted={answer.highlight}
                    onClick={() => handleOnAnswerSelected(answer)}
                  />
                ))}
              </Box>

              {question?.additionalInfo && (
                <QuizAdditionalInfoBox
                  content={question.additionalInfo}
                  position="initial"
                  width="auto"
                />
              )}
            </Col>
          </Row>
        </Grid>
      </Box>
    </Box>
  )
}
