import type { TouchEvent } from 'react'
import { useState } from 'react'

export const useSwipeHandlers = (handleNext: VoidFunction, handlePrevious: VoidFunction) => {
  const [touchStartPosition, setTouchStartPosition] = useState(0)
  const [touchStartPositionY, setTouchStartPositionY] = useState(0)

  /**
   * Logic for swipe gestures when starting the swipe
   */
  const handleSwipeStart = (event: TouchEvent<HTMLDivElement>) => {
    setTouchStartPosition(event.touches[0].clientX)
    setTouchStartPositionY(event.touches[0].clientY)
  }

  /**
   * Logic for swipe gestures when swipe has ended
   */
  const handleSwipeEnd = (event: TouchEvent<HTMLDivElement>) => {
    const touchEndPosition = event.changedTouches[0].clientX
    const touchEndPositionY = event.changedTouches[0].clientY
    const deltaX = touchEndPosition - touchStartPosition
    const deltaY = touchEndPositionY - touchStartPositionY

    // If that was a tap, don't do anything
    if (deltaX === 0 && deltaY === 0) {
      return
    }

    // If the swipe is more vertical than horizontal, don't do anything
    if (Math.abs(deltaY) > 10 && Math.abs(deltaX) < 100) {
      return
    }

    if (deltaX > 0) {
      handlePrevious()
    } else {
      handleNext()
    }
  }

  return {
    handleSwipeStart,
    handleSwipeEnd,
  }
}
