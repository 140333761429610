import { useEffect } from 'react'

const HUBSPOT_SCRIPT_URL = 'https://js.hsforms.net/forms/v2.js'

const HubSpotForm = () => {
  useEffect(() => {
    const existingScript = document.querySelector(`script[src="${HUBSPOT_SCRIPT_URL}"]`)
    if (!existingScript) {
      const script = document.createElement('script')
      script.src = HUBSPOT_SCRIPT_URL
      script.async = true
      script.addEventListener('load', () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            portalId: '9184050',
            formId: '37ee3d7d-b65a-4ebe-825f-f80cbb479aef',
            target: '#hubspotForm',
          })
        }
      })
      document.head.append(script)
    } else if (window.hbspt) {
      window.hbspt.forms.create({
        portalId: '9184050',
        formId: '37ee3d7d-b65a-4ebe-825f-f80cbb479aef',
        target: '#hubspotForm',
      })
    }
  }, [])

  return <div id="hubspotForm"></div>
}

export default HubSpotForm
