'use client'

import { Box, Section, spacing } from '@fortum/elemental-ui'

import { StyledHeading } from '@/shared/components/StyledHeading'
import type { IframeSectionEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'

import HubSpotForm from './parts/HubSpotForm'

export type IframeSectionProps = IframeSectionEntry

/**
 * Section for showing a page in an iframe.
 * Embedded hostnames must be added to the `frame-src` directive in the content security policy.
 */
export const IframeSection = ({ title, url, accessibleName }: IframeSectionProps) => {
  const { colors } = useTheme()

  return (
    <Section background={colors.backgroundSecondary}>
      <Box display="flex" flexDirection="column" gap={spacing.s}>
        {title && (
          <StyledHeading level={2} styledAs={3}>
            {title}
          </StyledHeading>
        )}
        {url?.includes('hsforms') ? (
          <HubSpotForm />
        ) : (
          <Box
            tag="iframe"
            width="100%"
            border={0}
            borderRadius={spacing.xxxs}
            background={colors.backgroundPrimary}
            height="min(calc(100dvh - 8rem), 40rem)"
            src={url || ''}
            aria-label={accessibleName || ''}
          />
        )}
      </Box>
    </Section>
  )
}
